import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as EquipmentIcon } from '../../svgs/cra-equipment-top-bar-icon.svg';
import Tooltip from '../../components/common/Tooltip';
import { dismissAllNotifications } from '../../store/equipment/equipmentActions';
import { toggleEquipmentPanel } from '../../store/ui/uiActions';
import { connect, useSelector } from 'react-redux';
import { selectEquipmentNotInOperation } from '../../store/api/selectors/equipment';
import { postEquipmentMetrics } from '../../store/api';

const StyledButton = styled.button`
  /* width: 2rem; */
  height: 2rem;
  /* position: absolute; */
  right: 30px;
  top: 15px;
  padding-left: 10px;
`;

const StyledMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  & span {
    cursor: pointer;
  }
`;

const StyledCounterBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-observations);
  color: var(--color-dark);
  font-weight: bold;
  width: 1.5em;
  /* height: 1.5em; */
  border-radius: 50%;
  ${(props) => (props.overrideWithStyle ? props.overrideWithStyle : null)};
`;

const EquipmentPanelButton = ({
  dismissAllNotifications,
  uiEquipmentPanelOpen,
  toggleEquipmentPanel,
}) => {
  const sessionId = useSelector((state) => state.api.sessionId);

  const handleEquipmentButtonClick = async () => {
    if (!uiEquipmentPanelOpen) {
      await postEquipmentMetrics(sessionId, 'EQUIPMENT_OPENED');
    }
    dismissAllNotifications([]);
    toggleEquipmentPanel(!uiEquipmentPanelOpen);
  };
  const equipmentStatuses = useSelector(selectEquipmentNotInOperation);

  return (
    <StyledButton
      onClick={() => {
        handleEquipmentButtonClick();
      }}
    >
      <Tooltip
        message={
          <StyledMessage>
            <span>Equipment</span>
          </StyledMessage>
        }
      >
        <StyledCounterBadge>{equipmentStatuses.length}</StyledCounterBadge>
        <EquipmentIcon fill="white" width="1.5rem" height="1.5rem" />
      </Tooltip>
    </StyledButton>
  );
};

const mapStateToProps = (state) => ({
  uiEquipmentPanelOpen: state.ui.uiEquipmentPanelOpen,
});

const mapDispatchToProps = (dispatch) => ({
  dismissAllNotifications: (empty) => dispatch(dismissAllNotifications(empty)),
  toggleEquipmentPanel: (uiEquipmentPanelOpen) =>
    dispatch(toggleEquipmentPanel(uiEquipmentPanelOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentPanelButton);
