import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import Icon from '../../components/common/Icon';
import Tooltip from '../../components/common/Tooltip';
import { getPreventionsForSelectedCause } from '../../store/ui/uiSelectors';
import { ReactComponent as IconShield } from '../../svgs/icon-shield.svg';
// import { getPreventions } from '../../utils';
import styles from './Card.module.css';
import { GRAPH_STATE_LABELS } from '../../constants';

const cardLayout = {
  observation: { columns: 'auto', areas: 'card' },
  consequence: { columns: 'max-content auto', areas: 'count card' },
  cause: { columns: 'auto max-content', areas: 'card count', iconColor: 'var(--color-green)' },
};

const StateSvgName = {
  false: 'cra-target',
  true: 'cra-hazard',
  breach: 'cra-breach',
  high: 'cra-high',
  low: 'cra-low',
  normal: 'cra-neutral',
  sensor: 'cra-sensor',
};

const StyledCardWrapper = styled.div`
  font-size: 0.78rem;
  display: flex;
  width: 100%;
`;

const PreventionsCountWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2d2f3c;
  font-weight: 700;
`;

const IconAndLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const PreventionsAndSensor = styled.div`
  display: flex;
  gap: ${(props) => props.withSensor && '1rem'};
`;

const ConsequenceCard = forwardRef((props, ref) => {
  const { onCardClick, disabled, id, type, isSelected, label, data, functionId } = props;

  const preventions = useSelector(getPreventionsForSelectedCause);
  const { description, state, isSensor, objectRole, category } = data;
  const consequencePreventions = preventions[functionId]?.[state];

  return (
    <button
      ref={ref}
      className={styles.buttonWrapper}
      type="button"
      onClick={onCardClick}
      disabled={disabled}
      data-id={id}
    >
      <StyledCardWrapper type={type}>
        <div
          className={`${styles.card} ${isSelected ? styles.cardSelected : null}  
          `}
        >
          <IconAndLabel>
            <div className={styles.cardState}>
              <Icon
                name={StateSvgName[state]}
                className={styles.cardStateIcon}
                height="40"
                width="40"
                fill="currentColor"
              />
            </div>
            {description?.length > 0 ? (
              <Tooltip message={description} objectRole={objectRole} category={category}>
                <div className={`${styles.cardBody} text-ellipsis ${styles.fixedWidth}`}>
                  <span className="text-bold text-ellipsis">{label}</span>
                  <div className="text-small color-gray">
                    <div className="text-ellipsis">{description}</div>
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div className={`${styles.cardBody} text-ellipsis ${styles.fixedWidth}`}>
                <span className="text-bold text-ellipsis">{label}</span>
                <div className="text-small color-gray">
                  <div className="text-ellipsis">{description}</div>
                </div>
              </div>
            )}
          </IconAndLabel>
          <PreventionsAndSensor withSensor={isSensor}>
            {/* <div className="sensor-icon">
              {isSensor && (
                <Tooltip message={'SENSOR READING'}>
                  <Icon
                    name={StateSvgName.sensor}
                    className="card__sensor-icon"
                    height="25"
                    width="25"
                    fill="currentColor"
                  />
                </Tooltip>
              )}
            </div> */}
            {preventions && consequencePreventions && consequencePreventions.length > 0 && (
              <PreventionsCountWrapper>
                <span style={{ position: 'absolute' }}>{consequencePreventions.length}</span>
                <IconShield fill="#FF9373" />
              </PreventionsCountWrapper>
            )}
          </PreventionsAndSensor>
        </div>
      </StyledCardWrapper>
    </button>
  );
});

ConsequenceCard.displayName = 'ConsequenceCard';
export default motion(ConsequenceCard);
