import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { selectEquipmentNotInOperation } from '../../store/api/selectors/equipment';
import { toggleRewind } from '../../store/mode/actions';
import { getOngoingObservations } from '../../store/timeline/timelineSelectors';
import { getAllManualObservations } from '../../store/ui/uiSelectors';

import { dismissAllNotifications } from '../../store/equipment/equipmentActions';
import { setRewindObseravationClicked, toggleEquipmentPanel } from '../../store/ui/uiActions';
import Timeline from './Timeline';
import TooltipPortal from '../common/TooltipPortal';

import './Observations.css';
import { modeIsRewind } from '../../store/mode/selectors';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react';
import { Icon } from '../common/Icon';
import { ReactComponent as IconReset } from '../../svgs/cra-reset.svg';
import { answerClick } from '../../store/verifyCause/verifyCauseActions';
import { postResetManualsMetrics, postRewindModeMetrics } from '../../store/api';
import HelpScreenLabel from '../common/HelpScreenLabel';

const StyledButtonRow = styled.div`
  display: grid;
  grid-gap: 1.2rem;
  padding: 0.5rem;
  grid-auto-flow: column;
  width: max-content;
  justify-self: end;
`;

const StyledObservationsButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  min-width: 10rem;
  justify-content: start;
  align-items: center;
  padding: 0.2em 0.75em 0.2em 0.2em;
  border-radius: 2em;
  grid-template-columns: max-content max-content;
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: var(--color-dark);
  }
`;

const ResetManualsButton = styled.button`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  min-width: 9rem;
  justify-content: start;
  align-items: center;
  padding: 0.2em 0.2em 0.2em 0.4em;
  border-radius: 2em;
  grid-template-columns: max-content max-content;
  transition: background-color 200ms ease-in-out;
  &:hover {
    background-color: var(--color-dark);
  }
`;

const ghostStyle = css`
  color: white;
  background-color: transparent;
`;

const StyledCounterBadge = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-observations);
  color: var(--color-dark);
  font-weight: bold;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  ${(props) => (props.overrideWithStyle ? props.overrideWithStyle : null)};
`;

const ResetButtons = styled.div`
  padding-top: 20px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
`;

const ResetConfirmButton = styled.button`
  padding: 5px 25px;
  border-radius: 15px;
  border: solid 2px var(--color-observations);
  font-size: 1rem;
  font-family: 'Roboto';
  background-color: var(--color-observations);
  color: var(--color-dark);
  margin: 5px 0;
`;

const ResetCancelButton = styled.button`
  padding: 5px 20px;
  border-radius: 15px;
  border: solid 2px var(--selected-bg-color, currentColor);
  font-size: 1rem;
  font-family: 'Roboto';
  background-color: var(--selected-bg-color, transparent);
  color: var(--selected-color, inherit);
  margin: 5px 0;
`;

const StyledIcon = styled(Icon)``;

function Observations({ dismissAllNotifications, uiEquipmentPanelOpen, toggleEquipmentPanel }) {
  const isRewind = useSelector(modeIsRewind);
  const dispatch = useDispatch();
  const timeline = useSelector(getOngoingObservations);
  const manualObservations = useSelector(getAllManualObservations);
  const sessionId = useSelector((state) => state.api.sessionId);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [coords, setCoords] = useState({});
 
  const style = {
    position: 'absolute',
    top: coords.top,
    left: coords.left,
    zIndex: '1000',
    background: 'var(--color-tooltip-bg)',
    padding: 15,
    transition: 'opacity 0.3s ease-in-out 0s',
    width: '260px',
  };

  const resetManualObservations = (manualObservations) => {
    manualObservations.forEach((element) => {
      const { functionId, state } = element;
      dispatch(answerClick({ ttl: 0, functionId, answer: 'clear', isNormal: true }));
      setTooltipOpen(false);
    });
  };

  const handleCancelResetClick = async () => {
    setTooltipOpen(false);
    await postResetManualsMetrics(sessionId, { isConfirmed: false });
  };

  const handleConfirmResetClick = async () => {
    resetManualObservations(manualObservations);
    setTooltipOpen(false);
    await postResetManualsMetrics(sessionId, { isConfirmed: true });
  };

  const handleRewindClick = async () => {
    dispatch(toggleRewind());
    if (isRewind) {
      dispatch(setRewindObseravationClicked(null));
      await postRewindModeMetrics(sessionId, 'RESUME_CLICKED');
    } else {
      await postRewindModeMetrics(sessionId, 'REWIND_CLICKED');
    }
  };
  return (
    <div className="observations__timeline">
      <div className="observations-head">
        <h2 className="section-title">{timeline.length} Confirmed Observations</h2>
        <StyledButtonRow>
          {/* <StyledObservationsButton
            type="button"
            onClick={() => {
              dismissAllNotifications([]);
              toggleEquipmentPanel(!uiEquipmentPanelOpen);
            }}
          >
            <StyledCounterBadge>{equipmentStatuses.length}</StyledCounterBadge>
            Equipment Status
          </StyledObservationsButton> */}
          <ResetManualsButton
            id="reset-manuals-confirmation"
            type="button"
            onClick={(e) => {
              const rect = e.target.getBoundingClientRect();
              setCoords({
                left: rect.x,
                top: rect.y + window.scrollY - (rect.height + 150),
              });
              setTooltipOpen(!tooltipOpen);
            }}
          >
            <IconReset strokeWidth="0" width="1rem" height="1rem" />
            Reset Manuals
          </ResetManualsButton>
          {tooltipOpen && (
            <TooltipPortal>
              <div style={style}>
                This will reset {manualObservations.length} manually confirmed observations. Do you
                want to continue?
                <ResetButtons>
                  <ResetCancelButton onClick={() => handleCancelResetClick()}>
                    Cancel
                  </ResetCancelButton>
                  <ResetConfirmButton onClick={() => handleConfirmResetClick()}>
                    Reset
                  </ResetConfirmButton>
                </ResetButtons>
              </div>
            </TooltipPortal>
          )}
          <StyledObservationsButton type="button" onClick={() => handleRewindClick()}>
            <StyledCounterBadge overrideWithStyle={isRewind ? null : ghostStyle}>
              {isRewind ? (
                <StyledIcon name="cra-icon-play" />
              ) : (
                <StyledIcon name="cra-icon-rewind" />
              )}
            </StyledCounterBadge>
            {isRewind ? 'Return To Current' : 'Rewind'}
          </StyledObservationsButton>
        </StyledButtonRow>
      </div>
      <div className="observations-scroll">
        <Timeline />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  uiEquipmentPanelOpen: state.ui.uiEquipmentPanelOpen,
});

const mapDispatchToProps = (dispatch) => ({
  dismissAllNotifications: (empty) => dispatch(dismissAllNotifications(empty)),
  toggleEquipmentPanel: (uiEquipmentPanelOpen) =>
    dispatch(toggleEquipmentPanel(uiEquipmentPanelOpen)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Observations);
