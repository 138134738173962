import { useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const ProtectedRoute = (props) => {
  const { element: Element } = props;
  const { ENABLE_AUTH } = useSelector((state) => state.api.flags);

  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated | !ENABLE_AUTH) return <Element />;
  return <Navigate to="/login" />;
};
