import React, { useCallback } from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import './Tooltip.css';
import Store from '../../containers/Store';
import styled from '@emotion/styled/macro';

const StyledTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const StyledCategoryAndRoleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const Tooltip = ({ children, message, objectRole, category }) => {
  const { TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
  });

  const { showTooltip, hideTooltip, tooltipOpen, tooltipData, tooltipLeft, tooltipTop } =
    useTooltip({
      tooltipOpen: false,
    });

  const tooltipStyles = {
    ...defaultStyles,
    backgroundColor: 'var(--color-tooltip-bg)',
    color: 'white',
    padding: 15,
    transition: 'opacity 0.3s ease-in-out 0s',
    zIndex: 1000,
  };

  const handlePointerMove = useCallback(
    (event) => {
      showTooltip({
        tooltipLeft: event.clientX ? event.clientX : 0,
        tooltipTop: event.clientY ? event.clientY : 0,
        tooltipData: message,
      });
    },
    [showTooltip, message]
  );

  return (
    <>
      <div
        className={`tooltip-wrapper`}
        onMouseEnter={handlePointerMove}
        onMouseLeave={hideTooltip}
      >
        {children}
      </div>
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()} // needed for bounds to update correctly
          left={tooltipLeft}
          top={tooltipTop}
          style={tooltipStyles}
        >
          <Store>
            <StyledTooltip>
              <span>{tooltipData}</span>
              <StyledCategoryAndRoleWrapper>
                {category && (
                  <span className="text-bold" style={{ textTransform: 'capitalize' }}>
                    {category}
                  </span>
                )}
                {objectRole && <span className="color-gray text-bold"> {objectRole}</span>}
              </StyledCategoryAndRoleWrapper>
            </StyledTooltip>
          </Store>
        </TooltipInPortal>
      )}
    </>
  );
};

export default Tooltip;
