import React from 'react';
// import ReactDOM from 'react-dom/client';
import * as ReactDOMClient from 'react-dom/client';

import './index.css';

import App from './containers/App';

import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalReactTester } from 'msal-react-tester';

//https://test.kairos-cra.no/blank.html : EXAMPLE AUTH_REDIRECT_URI
//process.env.AUTH_REDIRECT_URI

const root = ReactDOMClient.createRoot(document.getElementById('root'));

const defaultUri = 'http://localhost:3000/blank.html';

const isLocalhost =
  window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const isInContainer = window.location.port === '';

const redirectUri = !isLocalhost
  ? `${window.location.protocol}//${window.location.hostname}/blank.html`
  : isInContainer
  ? 'http://localhost/blank.html'
  : defaultUri;

const msalConfig = {
  auth: {
    clientId: 'c04a747d-3203-4265-a650-afb69864b9a7',
    authority: 'https://kairoshazop.b2clogin.com/kairoshazop.onmicrosoft.com/B2C_1_signin', // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri,
    knownAuthorities: ['kairoshazop.b2clogin.com'],
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: false,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export const msalTester = new MsalReactTester();
// export const msalTester = null;
// msalTester.getLogger();
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
  // document.getElementById('root')
);
