import { DateTime, Interval } from 'luxon';

export function calculateBars(now, then) {
  //const then = now.minus({ minutes });
  const interval = Interval.fromDateTimes(then, now).length();

  return function bar(start, end) {
    const started = start ? DateTime.fromISO(start).toMillis() : then.toMillis();
    const ended = end ? DateTime.fromISO(end).toMillis() : now.toMillis();

    const startRatio = (started - then) / interval;
    const startPercentage = clamp(startRatio * 100, 0, 100);

    const endRatio = (ended - then) / interval;
    const endPercentage = clamp(endRatio * 100, 0, 100);

    const difference = endPercentage - startPercentage;

    return { startRatio, startPercentage, endRatio, endPercentage, difference };
  };
}

export const calculateFadingStage = (time) => {
  let diff = DateTime.fromISO(time).diffNow('minutes').toObject().minutes;
  diff = diff * -1;

  switch (true) {
    case diff < 10:
      return 0;
    case diff < 20:
      return 0.3;
    case diff < 25:
      return 0.5;
    case diff < 30:
      return 0.7;
    case diff > 30:
      return 0.9;
    default:
      break;
  }
};

export function clamp(num, min, max) {
  return Math.min(Math.max(num, min), max);
}

export function differenceTime(timestamp) {
  const dt1 = DateTime.fromISO(timestamp);
  const dt2 = DateTime.local();

  return Interval.fromDateTimes(dt1, dt2).toDuration(['days', 'hours', 'minutes']).toObject();
}

export function formatDate(timestamp, format = 'HH:mm') {
  return DateTime.fromISO(timestamp).toFormat(format);
}

export function elapsedTime(timestamp) {
  const diffTime = DateTime.local().diff(DateTime.fromISO(timestamp), ['minutes']);
  const minutesPassed = diffTime && diffTime.values ? Math.round(diffTime.values.minutes) : 200;
  return minutesPassed < 200 ? minutesPassed : 200;
}

export function relativeTime(timestamp) {
  const difference = differenceTime(timestamp);
  if (difference.minutes <= 1) {
    return 'now';
  }
  return DateTime.local().minus(difference).toRelative({ style: 'narrow', locale: 'en-gb' });
}

export function timeFormatHours(time) {
  return time.toFormat('HH:mm');
}
