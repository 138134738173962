import React, { useCallback, useEffect, useRef } from 'react';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import './Tooltip.css';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled/macro';
import { Icon } from '../../components/common/Icon';
import RankingIndicator from '../../components/ranking/RankingIndicator';
import { AnimatePresence, motion } from 'framer-motion';
import { ToolIcon } from '../../components/svgIcons/cra-tool';
import Card from '../cards/Card';

const HelpKeyContainer = styled.div`
    margin: auto;
    left: 0;
    right: 0;
    top: 3%;
    position: absolute;
    z-index: 9999999999;
    background-color: var(--color-charcoal);
    width: 90%;
    /* height: 90%; */
    border-radius: 5px;
    font-size: 70%;
    padding: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

const RankingTooltipIcon = styled(motion.div)`
  display: inline-block;
  margin-right: 15px;
  margin-top: 5px;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: ${(props) => {
    if (props.color === 'orange') {
      return 'var(--color-observations)';
    }
    if (props.color === 'gray') {
      return 'var(--color-gray)';
    }
    if (props.color === 'darkest') {
      return 'var(--color-darkest)';
    }
  }};
`;

const HelpScreenKey = () => {
    const helpKeyOpen = useSelector((state) => state.ui.uiHelpKeyOpen);


    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        detectBounds: true,
    });

    const { showTooltip, hideTooltip, tooltipOpen, tooltipData, tooltipLeft, tooltipTop, labelBottomOrTop = 'top', labelLeftOrRight = 'left' } =
        useTooltip({
        tooltipOpen: true,
        });

    const relativeRank = {positive: 0.3427960752486861, negative: 0.2212};

    const elementRef = useRef(null);

    const RankingIndicatorTooltip = () => {
        return (
          <>
            <RankingTooltipIcon color="orange"></RankingTooltipIcon>
            <span>Supporting observations</span>
            <div />
            <RankingTooltipIcon color="gray"></RankingTooltipIcon>
            <span>Conflicting observations</span>
            <div />
            <RankingTooltipIcon color="darkest"></RankingTooltipIcon>
            <span>No observation received</span>
          </>
        );
      };

    useEffect(() => {
        if(elementRef.current){
            const rect = elementRef.current.getBoundingClientRect();
            showTooltip({
                tooltipLeft: rect.left,
                tooltipTop: rect.top
            }); 
        }
    }, []);

    const card1Data = {
        "label": "59C-327 High entrainment of water/amine",
        "description": "AGE Absorber",
        "isSensor": false,
        "concept": "tar",
        "objectRole": "",
        "category": "other",
        "state": "target",
        "uuid": "2.9713_false",
        "functionId": "2.9713",
        "rank": 0.800,
        "relativeRank": {
          "positive": 0.800,
          "negative": 0
        },
        "observationFunctions": [
          "2.509"
        ],
        "contradictingFunctions": [],
        "instrumentationFunctions": [
          {
            "state": "*",
            "functionId": "2.9123"
          }
        ],
        "preventive": []
      };


    const card2Data = {
        "label": "59C-327 LV3033 Opening",
        "limits": [
          {
            "severity": "high",
            "limit": 80
          },
          {
            "severity": "low",
            "limit": 20
          }
        ],
        "sensor": "",
        "description": "AGE Absorber",
        "isSensor": true,
        "concept": "tra",
        "objectRole": "",
        "category": "other",
        "state": "low",
        "uuid": "2.770_low",
        "functionId": "2.770",
        "rank": 0.5,
        "relativeRank": {
          "positive": 0.5,
          "negative": 0
        },
        "observationFunctions": [
          "2.509"
        ],
        "contradictingFunctions": [],
        "instrumentationFunctions": [
          {
            "state": "*",
            "functionId": "2.9123"
          }
        ],
        "preventive": []
      };

      const card3Data = {
        "label": " Acid gas combine flow",
        "description": "Gas from treating unit",
        "isSensor": false,
        "concept": "sou",
        "objectRole": "Gas",
        "category": "other",
        "state": "high",
        "uuid": "2.524_high",
        "functionId": "2.524",
        "rank": 0.2455,
        "relativeRank": {
          "positive": 0.2455,
          "negative": 0.4655
        },
        "observationFunctions": [
          "2.527"
        ],
        "contradictingFunctions": [],
        "instrumentationFunctions": [
          {
            "state": "*",
            "functionId": "140.1469"
          }
        ],
        "preventive": []
      };


    return (
        <HelpKeyContainer className='helpScreenKey'>

            <div className="helpKeyCol1">
                <h1 className='iconHeader'>Icons & Colors</h1>
                <div className='iconColContainer'>
                    <div className="iconCol">
                        {/* <h3>Observation States</h3> */}
                        <div className='iconArea legend-box observations-box'>
                            <h2>Observations</h2>
                            <div className='iconRow'>  
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-limit-high'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>High Limit</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-limit-low'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Low Limit</span>
                                </div>
                            </div>
                            <div className='iconRow'>

                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-normal'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Normal Observation</span>
                                </div>   
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-obs-high'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>High Observation</span>
                                </div>
                            </div>
                            <div className='iconRow'> 
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-obs-low'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Low Observation</span>
                                </div>
                                <div className='iconItem blank'>
                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="iconCol">                    
                        <div className='iconArea legend-box causes-box'>
                            <h2>Root Causes: Failure States</h2>

                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-obs-breach'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Breach</span>

                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-target'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                        className="helpscreenIcon"
                                    />
                                    <span className='iconLabel'>Target</span>
                                </div>
                            
                                
                            </div>
                            <div className='iconRow'>  
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-mco-high'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Control Failure High</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-mco-low'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />    
                                    <span className='iconLabel'>Control Failure Low</span>  
                                </div> 
                            </div>
                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-hazard'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Hazard</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-neutral'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                        className="helpscreenIcon"
                                    />
                                    <span className='iconLabel'>Neutral</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="iconCol">
                        
                        <div className='iconArea legend-box consequences-box'>
                            <h2>Consequences: Failure States</h2>

                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-obs-breach'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Breach</span>

                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-target'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                        className="helpscreenIcon"
                                    />
                                    <span className='iconLabel'>Target</span>
                                </div>
                            
                                
                            </div>
                            <div className='iconRow'>  
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-mco-high'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Control Failure High</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-mco-low'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />    
                                    <span className='iconLabel'>Control Failure Low</span>  
                                </div> 
                            </div>
                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-hazard'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Hazard</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-neutral'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                        className="helpscreenIcon"
                                    />
                                    <span className='iconLabel'>Neutral</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="iconCol">
                        <div className='iconArea legend-box actuator-box'>
                            <h2>Actuators</h2>
                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-tool'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Corrections</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'icon-shield'}
                                        height="30"
                                        width="30"
                                        stroke="black"
                                        strokeWidth="2"
                                        fill="none"
                                    />
                                    <span className='iconLabel'>Preventions</span>
                                </div>
                            </div>
                        </div>
                        
                        <div className='iconArea legend-box ui-box'>
                            <h2>UI Elements</h2>
                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-sensor'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Transmitter</span>
                                </div>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-icon-rewind'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Rewind Mode</span>
                                </div>    
                            </div>
                            <div className='iconRow'>
                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-manual-verify'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Manual Verification</span>
                                </div>

                                <div className='iconItem'>
                                    <Icon
                                        name={'cra-equipment-top-bar-icon'}
                                        height="30"
                                        width="30"
                                        fill="currentColor"
                                    />
                                    <span className='iconLabel'>Equipment Menu</span>
                                </div>
                            </div>
                        </div>
                    </div>
                                    
                    {/* <div className="colorCol">
                        <h3>Colors</h3>
                        <div className='colorGuide legend-box'>
                            <div className='colorRow'>
                                <div className='colorItem'>
                                    <div className='guide-circle observations-color'>Observations</div>
                                </div>
                                <div className='colorItem'>
                                    <div className='guide-circle causes-color'>Root Causes</div>
                                </div>
                                <div className='colorItem'>
                                    <div className='guide-circle consequences-color'>End Consequences</div>
                                </div>
                                <div className='colorItem'>
                                    <div className='guide-circle actuator-color'>Actuators</div>
                                </div>
                                
                            </div>
                        </div>
                        <h3 className='rankingHeading'>Ranking</h3>
                        <div className='rankingBox legend-box'>
                            <div className='rankingDonut'>
                                <RankingIndicator rank={relativeRank} height="50" width="50" />
                            </div>
                            <div className='rankingTooltipContainer'>
                            
                                <div className='rankItem'>
                                    <RankingTooltipIcon color="orange"></RankingTooltipIcon>
                                    <span>Supporting observations</span>
                                </div>
                                <div className='rankItem'>
                                    <RankingTooltipIcon color="gray"></RankingTooltipIcon>
                                    <span>Conflicting observations</span>
                                </div>
                                <div className='rankItem'>
                                    <RankingTooltipIcon color="darkest"></RankingTooltipIcon>
                                    <span>No observation received</span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className='rankRow'>
                <h1 className='rankingHeading'>Ranking</h1>
                <div className='rankingBox legend-box'>
                    <div className='rankingDonut'>
                        <RankingIndicator thickness={10} rank={relativeRank} height="100" width="100" />
                    </div>
                    <div className='rankingTooltipContainer'>
                        
                        <div className='rankItem'>
                            <RankingTooltipIcon color="orange"></RankingTooltipIcon>
                            <span>Supporting observations</span>
                        </div>
                        <div className='rankItem'>
                            <RankingTooltipIcon color="gray"></RankingTooltipIcon>
                            <span>Conflicting observations</span>
                        </div>
                        <div className='rankItem'>
                            <RankingTooltipIcon color="darkest"></RankingTooltipIcon>
                            <span>No observation received</span>
                        </div>
                    </div>
                    <div className='rankHelpCards'>
                        <div className='cardContainer'>
                            <h5>1st Ranked Item</h5>
                            <span>This item is ranked first because the majority of the possible observations contribute supporting evidence. There is still some space unfilled bcause information has not been obtained from all transmitters.</span>
                            <Card
                                type={'cause'}
                                key={'1234'}
                                isSelected={false}
                                data={card1Data}
                                label={"59C-327 High entrainment of water/amine"}
                                id={"2.9713_false"}
                                onCardClick={() => {}}
                                count={2}
                                className="rankingAreaCard"
                                labels={"target"}
                            />
                        </div>
                        <div className='cardContainer'>
                            <h5>2nd Ranked Item</h5>
                            <span>This item is ranked second because a smaller portion of the possible observations support this as the root cause. A full circle would indicate support from all instrumented transmitters.</span>
                            <Card
                                type={'cause'}
                                key={'1234'}
                                isSelected={false}
                                data={card2Data}
                                label={"59C-327 LV3033 Opening"}
                                id={"2.9713_false"}
                                onCardClick={() => {}}
                                count={2}
                                labels={"haz"}
                                className="rankingAreaCard"
                            />
                        </div>
                        <div className='cardContainer'>
                            <h5>3rd Ranked Item</h5>
                            <span>In addition to this item having the lowest overall ranking, there are observations that contradict this as a likely root cause. These are represented by the gray section at the end of the ranking circle.</span>
                            <Card
                                type={'cause'}
                                key={'1234'}
                                isSelected={false}
                                data={card3Data}
                                label={"Acid gas combine flow"}
                                id={"2.524_high"}
                                onCardClick={() => {}}
                                count={2}
                                labels={"breach"}
                                className="rankingAreaCard"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottomRow">
                <div className='insights'>
                    <h1>Real Time Insight</h1>
                    <div className='blurbArea legend-box '>
                        <span>
                            Our Control Room Assistant (CRA) is always on the lookout, providing control room operators with real-time support and guidance. It quickly identifies abnormal situations, pinpoints root causes, forecasts potential consequences and supplies suggested mitigation plans. Thanks to our CRA system, operators can be confident that they will be presented with the cause behind an issue and its effects along with proposed counteraction steps at a moment&apos;s notice. We guarantee to assist them with all the necessary information and advice they need while working.
                        </span>
                    </div>
                </div>
                <div className='links'>
                    <h1>Helpful Links</h1>
                    <div className='blurbArea legend-box'>
                      <ul>
                        <li><a href='https://edms.gasco.ae/livelink/livelink.exe?func=ll&objId=56775489&objAction=browse&viewType=1'>Operating Manual</a></li>
                        <li><a href="https://edms.gasco.ae/livelink/livelink.exe?func=ll&objId=52274261&objAction=browse&viewType=1">PFDs</a></li>
                        <li><a href="https://edms.gasco.ae/livelink/livelink.exe?func=ll&objId=44995084&objAction=browse&viewType=1">P&IDs</a></li>
                        <li><a href="https://edms.gasco.ae/livelink/livelink.exe?func=ll&objId=44737419&objAction=browse&viewType=1">DCS Graphics</a></li>
                      </ul>
                    </div>
                </div>
            </div>
        </HelpKeyContainer>
    );
};

export default HelpScreenKey;
