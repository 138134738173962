import { useMsal } from '@azure/msal-react';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const StyledButton = styled.button`
  padding: 10px 30px;
  border-radius: 100px;
  background-color: var(--color-charcoal);
  color: var(--color-white);
  text-align: center;
  font-weight: 700;
  font-size: 1.2rem;
`;

const loginRequest = {
  scopes: ['https://kairoshazop.onmicrosoft.com/portal-api/portal.read'],
};

export const SignInButton = ({ setShowErrorMessage }) => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const handleLogin = async (instance) => {
    try {
      const loginData = await instance.loginPopup(loginRequest);
      navigate('/');
    } catch (error) {
      console.log(error);
      setShowErrorMessage(true);
    }
  };

  return <StyledButton onClick={() => handleLogin(instance)}>Sign In</StyledButton>;
};
