import React, { forwardRef } from 'react';
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';

import './Card.css';
import { Icon } from '../common/Icon';
import Tooltip from '../common/Tooltip';
import CountCircle from '../common/CountCircle';
import RankingIndicator from '../ranking/RankingIndicator';
import { scaleOrdinal } from '@visx/scale';

const cardLayout = {
  observation: { columns: 'auto', areas: 'card' },
  consequence: { columns: 'max-content auto', areas: 'count card' },
  cause: {
    columns: 'auto max-content',
    areas: 'card count',
    iconColor: 'var(--color-root-causes)',
  },
};

const StateSvgName = {
  target: 'cra-target',
  hazard: 'cra-hazard',
  breach: 'cra-breach',
  high: 'cra-high',
  low: 'cra-low',
  normal: 'cra-neutral',
  sensor: 'cra-sensor',
  mcoHigh: 'cra-mco-high',
  mcoLow: 'cra-mco-low',
};

const StyledCardWrapper = styled.div`
  font-size: 0.78rem;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 0.5rem;
  grid-template-columns: ${({ type }) => cardLayout[type].columns};
  grid-template-areas: '${({ type }) => cardLayout[type].areas}';
  --card-icon-color: ${({ type }) => cardLayout[type].iconColor};
`;

const Card = forwardRef(
  (
    {
      data,
      label,
      isSelected,
      isExpanded,
      onCardClick,
      disabled,
      hovered,
      count,
      onMouseEnter,
      onMouseLeave,
      id,
      type,
    },
    ref
  ) => {
    const { description, state, isSensor, rank, relativeRank, concept } = data;

    const iconByConceptAndState =
      concept === 'mco' || concept === 'dco' || concept === 'sup'
        ? state === 'high'
          ? 'mcoHigh'
          : 'mcoLow'
        : state;

    return (
      <button
        className={'button-wrapper'}
        type="button"
        onClick={onCardClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
        data-id={id}
        ref={ref}
      >
        <StyledCardWrapper type={type}>
          <div
            className={`card ${isSelected ? 'card-selected' : 'card-unselected'} ${
              isExpanded ? 'card-expanded' : ''
            } ${hovered ? 'card-hovered' : ''}`}
          >
            <div className="card-state">
              <Icon
                name={StateSvgName[iconByConceptAndState]}
                className="card-state__icon"
                height="40"
                width="40"
                fill="currentColor"
              />
            </div>
            {description?.length > 0 ? (
              <Tooltip message={description}>
                <div className="card-body text-ellipsis">
                  <span className="text-bold text-ellipsis">{label}</span>
                  <div className="text-small color-gray">
                    <div className="text-ellipsis">{description}</div>
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div className="card-body text-ellipsis">
                <span className="text-bold text-ellipsis">{label}</span>
                <div className="text-small color-gray">
                  <div className="text-ellipsis">{description}</div>
                </div>
              </div>
            )}
            <div className="sensor-icon">
              {/* {isSensor && (
                <Tooltip message={'SENSOR READING'}>
                  <Icon
                    name={StateSvgName.sensor}
                    className="card__sensor-icon"
                    height="25"
                    width="25"
                    fill="currentColor"
                  />
                </Tooltip>
              )} */}
            </div>
            <div>
              <RankingIndicator rank={relativeRank} height="40" width="40" />
            </div>
          </div>
        </StyledCardWrapper>
      </button>
    );
  }
);

Card.displayName = 'Card';
export default motion(Card);
